// 导入自己需要的组件
import {
  // Select,
  // Option,
  // OptionGroup,
  // Input,
  // Tree,
  Dialog,
  Carousel,
  CarouselItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  InfiniteScroll
} from 'element-ui'
const element = {
  install: function (Vue) {
    // Vue.use(Select)
    // Vue.use(Option)
    // Vue.use(OptionGroup)
    // Vue.use(Input)
    // Vue.use(Tree)
    Vue.use(Dialog)
    Vue.use(Carousel)
    Vue.use(CarouselItem)
    Vue.use(Dropdown)
    Vue.use(DropdownMenu)
    Vue.use(DropdownItem)
    Vue.use(InfiniteScroll)
  }
}
export default element
