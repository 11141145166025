export const common = {
  /* 转换时间戳为时间 */
  getFormatOfDate(date, format) {
    var that = new Date(date * 1);
    var date = {
      "M+": that.getMonth() + 1,
      "d+": that.getDate(),
      "h+": that.getHours(),
      "m+": that.getMinutes(),
      "s+": that.getSeconds(),
      "q+": Math.floor((that.getMonth() + 3) / 3),
      "S+": that.getMilliseconds()
    };
    if (/(y+)/i.test(format)) {
      format = format.replace(RegExp.$1, (that.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (var k in date) {
      if (new RegExp("(" + k + ")").test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length == 1 ?
          date[k] : ("00" + date[k]).substr(("" + date[k]).length));
      }
    }
    return format;
  },
}
export default {
  common
}
