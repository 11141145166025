const json = {
  // 测试环境
  // "baseURL": "https://apim.vjuzhen.com/",
  // "shopId": "865783",
  // "picHead": "https://resource.qizhigo.com/"
  // 正式环境
  "baseURL": "https://api.m.qizhigo.com/",
  // "shopId": "146178",
  // "shopId": "613186",
  "picHead": "https://resource.qizhigo.com/"
}
export default json
