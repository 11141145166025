<template>
  <div id="app" ref="my_pull" :class="theme" v-if="shopId">
    <header>
      <div class="header-con">
        <div class="logo">
          <a href="/" ><img :src="picHead + indexLogo" alt="" /></a>
        </div>
        <div class="classify">
          <div
            v-for="(item, index) in navTypesData"
            :key="item.id"
            :class="{ active: index == activeIndex, item: true }"
            v-if="index < 6"
            @click="toSwitch(item.id, index, item.name)"
          >
            {{ item.name }}
          </div>
          <div v-if="navTypesData.length > 6" class="item">
            <el-dropdown>
              <span class="el-dropdown-link">
                全部分类<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :class="{
                    active: index + 6 == activeIndex,
                  }"
                  v-for="(item, index) in navTypesData.slice(
                    6,
                    navTypesData.length
                  )"
                  :key="index"
                  @click.native="toSwitch(item.id, 6 + index, item.name)"
                  >{{ item.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="mini-logo">
          <el-dropdown>
            <span class="el-dropdown-link cur-p dis-flex">
              <!-- <i class="el-icon-menu"></i> -->
              <svg style="height:50px;width:40px" t="1610436596255" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1926" width="200" height="200"><path d="M534.528 146.285714A388.388571 388.388571 0 0 0 146.285714 534.528a388.388571 388.388571 0 0 0 388.242286 388.242286 388.388571 388.388571 0 0 0 388.242286-388.242286A387.900952 387.900952 0 0 0 534.528 146.285714z m128.877714 416.963048c-17.066667 3.120762-31.841524 0-39.594666-16.286476-6.972952-15.555048 3.120762-26.428952 13.994666-35.742476 5.412571-4.632381 11.605333-7.801905 17.846857-10.873905 25.6-14.726095 47.34781-34.133333 34.133334-66.80381-11.605333-30.232381-39.594667-38.034286-69.095619-34.133333-34.133333 4.681143-52.809143 24.868571-52.809143 59.782095 0 53.589333 1.560381 107.958857-0.731429 161.499429-3.120762 60.562286-67.584 114.151619-129.706666 111.811047-66.80381-2.29181-120.344381-56.661333-119.564191-136.630857 1.560381-37.302857 34.962286-67.584 80.749714-87.771428 19.407238-8.533333 38.034286-8.533333 48.908191 13.214476 12.434286 25.648762-8.533333 34.133333-25.6 41.935238-34.133333 14.774857-51.248762 38.814476-34.962286 73.776762 15.555048 32.572952 47.396571 39.594667 80.749714 27.940571 27.989333-9.313524 38.863238-31.841524 38.034286-61.342476a4574.159238 4574.159238 0 0 1 0-145.993143c0.828952-58.221714 27.989333-95.47581 81.578667-114.883047 48.90819-17.066667 109.470476-1.560381 142.872381 37.254095 49.688381 61.342476 11.605333 166.960762-66.80381 183.247238z" p-id="1927"></path></svg>
            </span>
            <el-dropdown-menu slot="dropdown" class="dropdown-menu-new">
              <div class="dropdown-item">
                <img :src="picHead + independentWxacode" alt="" class="w-105" />
                <div>{{hostCompany}}小程序</div>
              </div>
              <div class="dropdown-item" v-if="publicAccImage">
                <img
                  :src="picHead + publicAccImage"
                  alt=""
                  class="w-105"
                />
                <div>{{hostCompany}}公众号</div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </header>
    <div class="con">
      <div class="con-auto">
        <router-view v-if="isRouterAlive"></router-view>
      </div>
    <footer>
      <div class="footer-title" :class="{'pb-20': !pcDesc}">{{ pcName }}</div>
      <div class="footer-subtitle" v-if="pcDesc">{{ pcDesc }}</div>
      <div class="footer-aboutUs" @click="toAboutUs">关于我们</div>
      <div class="footer-remark">
        © {{ new Date().getFullYear() }} {{ hostCompany }} <span v-html="indepUrlNo"></span><!-- 京ICP备13032365号-1 -->
      </div>
    </footer>
    </div>
  </div>
</template>
<script>
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      shopId: '',
      theme: '',
      isRouterAlive: true,
      activeIndex: 0,
      activeName: '',
      navTypesData: [],
      independentWxacode: '', // 独立版小程序码
      // 店铺信息====开始
      // adminId: '',
      hostCompany: '',
      indepUrlNo: '',
      pcName: '',
      pcDesc: '',
      indexLogo: '',
      publicAccImage:'', // 公众号码
      // 店铺信息====结束
    };
  },
  created() {
    this.getShopIdByPreUrl()
    this.activeIndex = this.$route.query.index ? this.$route.query.index : 0;
  },
  computed: {
    picHead() {
      return this.$store.state.picHead;
    },
  },
  watch: {
    $route(to, from) {
      if (to.path != '/') {
        this.activeIndex = null;
      } else {
        this.activeIndex = this.$route.query.index
          ? this.$route.query.index
          : 0;
      }
    },
  },
  methods: {
    getShopIdByPreUrl() {
      let preUrl = window.location.hostname.split('.')[0];
      let subDomain = window.location.hostname.split('.')[1];
      // 独立域名 pkucxpl.com
      // 不是独立域名(qizhigo) wj
      if (subDomain == 'qizhigo') {
        // 不是独立域名
        preUrl = preUrl == 'localhost' ? 'wj' : preUrl;
      } else {
        // 独立域名
        preUrl = preUrl == 'localhost' ? 'pkucxpl.com' : window.location.hostname;
      }
      this.$request(this.$api.getShopIdByPreUrl+'?preUrl='+preUrl).then((res) => {
          if (res.success) {
            this.shopId = res.datas
            // this.shopId = 146178 // 613186
            console.log('shopId===App.vue', this.shopId);
            this.$store.commit('setShopId', this.shopId);
            if (this.shopId) {
              this.getInit();
            }
          }
      })
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    toAboutUs() {
      let routeData = this.$router.resolve({
        name: 'AboutUs',
      });
      window.open(routeData.href, '_blank');
    },
    getInit() {
      this.$request(this.$api.getAdminUsers).then((res) => {
        if (res.success) {
          this.adminId = res.datas.id;
          this.hostCompany = res.datas.hostCompany;
          this.indepUrlNo = res.datas.indepUrlNo;
          // 关于我们==开始
          this.pcName = res.datas.pcName;
          this.pcDesc = res.datas.pcDesc;
          // 关于我们==结束
          localStorage.removeItem('hostCompany');
          localStorage.setItem('hostCompany', this.hostCompany);
          this.indexLogo = res.datas.indexLogo;
          this.publicAccImage = res.datas.publicAccImage;
          console.log('模版', res.datas.webTemplate);
          this.$store.commit('setWebTemplate', res.datas.webTemplate);

          var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = this.picHead + res.datas.favicon;
          document.getElementsByTagName('head')[0].appendChild(link);

          // title
          document.title = res.datas.hostCompany

          // keywords
          var oMetaK = document.createElement('meta');
          oMetaK.content = res.datas.seoKeyWord;
          oMetaK.name = 'keywords';
          document.getElementsByTagName('head')[0].appendChild(oMetaK);

          // description
          var oMetaD = document.createElement('meta');
          oMetaD.content = res.datas.seoDesc;
          oMetaD.name = 'description';
          document.getElementsByTagName('head')[0].appendChild(oMetaD);

          switch (res.datas.colorType) {
            case 1:
              this.theme = "theme-b";
              break;
            case 2:
              this.theme = "theme-r";
              break;
            case 3:
              this.theme = "theme-y";
              break;
            default:
              this.theme = "theme-b";
              break;
          }
        }
      });
      // 获取分类信息
      this.$request(this.$api.getNavtypes, { type: 1 }).then((res) => {
        if (res.success) {
          this.navTypesData = res.datas.navTypes;
          this.navTypesData.unshift({ name: '首页', id: '' });

          if (this.activeIndex == 0) {
            this.activeName = this.hostCompany;
          } else if (this.activeIndex) {
            this.activeName =
              this.navTypesData[this.activeIndex].name + '_' + this.hostCompany;
          }
          if (!document.title) {
            document.title = this.activeName;
          }
          console.log('======title======', this.activeName);
          console.log(this.navTypesData);
        }
      });
      this.$request(this.$api.getIndependentWxacode, {
        // adminId: '5a4b369ef62342523858a57f',
      }).then((res) => {
        if (res.success) {
          this.independentWxacode = res.datas;
        }
      });
    },
    // 切换分类
    toSwitch(navTypeId, index, name) {
      console.log('切换分类', navTypeId, index, name);
      this.activeIndex = index;
      if (index == 0) {
        this.activeName = this.hostCompany;
      } else {
        this.activeName =
          this.navTypesData[this.activeIndex].name + '_' + this.hostCompany;
      }
      this.$router.push({
        name: 'Home',
        query: {
          index: index,
          navTypeId: navTypeId,
        },
      });
      document.title = this.activeName;
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  background-color: #f0f0f0;
  //   font-family: Avenir, Helvetica, Arial, sans-serif;
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
  //   text-align: center;
  //   color: #2c3e50;
  header {
    height: 50px;
    background: white;
    overflow: hidden;
    position: fixed;
    z-index: 99;
    width: 100%;
    .header-con {
      width: 1010px;
      height: 50px;
      display: flex;
      align-items: center;
      margin: auto;
    }
    .logo {
      height: 100%;
      a {
        display: inline-flex;
        height: 100%;
        max-width: 220px;
        align-items: center;
        justify-content: center;
        padding: 5px 0;
        box-sizing: border-box;
        img {
          height: 100%;
          max-width: 220px;
          object-fit: scale-down;
        }
      }
    }
    .classify {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .item {
        margin-right: 50px;
        // width: 20%;
        box-sizing: border-box;
        cursor: pointer;
        color: #101010;
        font-size: 14px;
      }
      .active {
        font-weight: bold;
      }
    }
    .mini-logo {
      /deep/.el-dropdown {
        font-size: 16px;
      }
    }
  }
  .con {
    padding-top: 60px;
    margin: auto;
    box-sizing: border-box;
    background: #eeeeee;
    overflow: auto;
    height: 100vh;
    .con-auto {
      width: 1010px;
      margin:0 auto;
      min-height: calc(100vh - 233px);
      // min-height: calc(100vh - 283px);
    }
  }
  footer {
    color: hsla(0, 0%, 100%, 0.9);
    text-align: center;
    padding: 22px 24px;
    .footer-title {
      font-size: 20px;
      height: 29px;
      line-height: 29px;
      font-family: Arial, Hiragino Sans GB, 冬青黑, Microsoft YaHei, 微软雅黑,
        SimSun, 宋体, Helvetica, Tahoma, Arial sans-serif;
    }
    .pb-20 {
      padding-bottom: 20px;
    }
    .footer-subtitle {
      line-height: 20px;
      font-size: 14px;
      padding: 10px 0px 20px 0px;
      width: 1010px;
      margin: auto;
    }
    .footer-aboutUs {
      width: 120px;
      height: 40px;
      line-height: 36px;
      font-size: 14px;
      border: 2px solid hsla(0, 0%, 100%, 0.9);
      box-sizing: border-box;
      margin: 0 auto;
      cursor: pointer;
    }
    .footer-aboutUs:hover {
      opacity: 0.8;
    }
    .footer-remark {
      font-size: 14px;
      height: 20px;
      line-height: 20px;
      padding-top: 20px;
    }
    a {
      color: white;
      text-decoration: none;
    }
  }
}
.el-dropdown {
  color: #101010;
  .el-dropdown-link i {
    color: #101010;
  }
}
</style>
<style lang="scss">
.dropdown-menu-new {
  display: flex;
  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 110px;
    padding: 0 25px !important;

    div {
      color: #101010;
      font-size: 12px;
      height: 20px;
      line-height: 20px;
    }
  }
  .dropdown-item:nth-child(2) {
    padding-right: 10px;
  }
}
</style>
