import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import './assets/styles/common.scss'

// css样式还是需要全部引入
import 'element-ui/lib/theme-chalk/index.css'
import './assets/styles/element-ui.scss' // 重置element样式
import './assets/styles/theme.scss' // 主题样式
import element from './element/index'
Vue.use(element)

// 封装接口==开始
import request from "./interface/request";
import api from "./interface/api";
Vue.prototype.$request = request;
Vue.prototype.$api = api;
// 封装接口==结束

import './mixin/filter'; // 全局过滤器

import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
