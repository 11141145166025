import axios from "axios";
import qs from 'qs'
import _con from "../../config/config.base"
axios.defaults.withCredentials = true;
import store from '../store';
console.log('shopId===request.js', store.state.shopId);

const HttpError = {
  400: "请求错误",
  401: "未授权，请登录",
  403: "拒绝访问",
  404: "求地址出错",
  408: "请求超时",
  500: "服务器开个小差，请稍后再试",
  501: "服务器开个小差，请稍后再试",
  502: "服务器开个小差，请稍后再试",
  503: "服务器开个小差，请稍后再试",
  504: "服务器开个小差，请稍后再试",
  505: "HTTP版本不受支持"
};

axios.interceptors.request.use(
  config => {
    console.log('config',config.url);
    let suffix = config.url.split('?')[0]
    console.log(333, suffix);
    if (suffix != 'getShopIdByPreUrl') {
      config.headers.shopId = store.state.shopId
      config.url = _con.baseURL + 'shops/' + store.state.shopId + config.url
    } else {
      config.url = _con.baseURL + config.url
    }
    config.data = qs.stringify(config.data) // 转为formdata数据格式
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 拦截响应response，并做一些错误处理
axios.interceptors.response.use(
  res => {
    try {
      // TODO: set response pre-judge-handler
    } catch (e) {
      console.info(
        `错误名称: ${e.name}\n错误描述: ${e.message}\n错误堆栈: ${e.stack}`
      );
    }
    return res;
  },

  err => {
    if (err && err.response && err.response.status == "401") {
      let url = err.response.data.loginUrl;
      let localUrl = escape(escape("#/" + location.href.split("/#/")[1]));
      setTimeout(() => {
        window.location.href = url + localUrl;
      }, 200);
    }
    // let msg =
    //   err.toString().indexOf("timeout") != -1
    //     ? "请求超时，请稍后再试"
    //     : "请求错误";
    // console.log(msg);
    // err && err.response && (msg = HttpError[err.response.status]);
    if (err && err.response && err.response.status) {
      err.message = HttpError[err.response.status]
    }
    return Promise.reject(err);
  }
);
/**
 * @func axios的二次封装
 * @param method-请求方法，url-接口地址，data-请求参数
 */
const request = (url, data = {}, method = "GET", style = '', headers = {}) => {
  // ;
  let params, paramsData;
  if (method.toUpperCase() === "GET") {
    params = "params";
  } else {
    params = "data";
  }
  paramsData = data
  return new Promise((resolve, reject) => {
    axios({
        url: url,
        method: method,
        [params]: paramsData,
        responseType: style,
        headers: headers
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        if (e && e.response && e.response.status == "500") {
          let response = JSON.parse(JSON.stringify(e.response));
          let message = response.data && response.data.message;
          if (message) {
            reject(e);
          } else {
            reject(response.data);
          }
        } else {
          reject(e);
        }
      });
  });
};

export default request;
