import store from '../store'
const shopId = store.state.shopId
console.log('shopId===api.js', shopId);

const api = {
  getShopIdByPreUrl: 'getShopIdByPreUrl', // 通过域名前缀获取shopid
  getNavtypes: `/navtypes`, // 获取文章列表
  getArticles: `/articles`, // 获取文章列表
  getArticlesDetail: `/articles/`, // 获取文章详情信息
  getAdminUsers: `/adminusers`, // 获取管理员账户信息
  getPictures: `/pictures`, // 获取banner图
  getIndependentWxacode: `/getIndependentWxacode`, // 获取店铺首页独立版小程序码
  getwxacode: `/wxacode`, // 生成内容详情页小程序码
}
export default api;
