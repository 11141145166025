import Vue from 'vue'
import Vuex from 'vuex'
import _con from "../../config/config.base"
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    picHead: _con.picHead,
    shopId: '',
    webTemplate: '', // 0 方格样式模版 1 列表样式模版
  },
  mutations: {
    setShopId(state, payload) {
      state.shopId = payload;
    },
    setWebTemplate(state, payload) {
      state.webTemplate = payload;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    reducer(val) {
        return {
          // 只储存state中的shopId  
          shopId: val.shopId,
          webTemplate: val.webTemplate,
        }
      }
  })]
})
